.shopPage>.shopPageLayout{
    margin: 100px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.shopPage>.shopPageLayout .sampleImageContent{
    width: 815px;
    margin: auto 0;
    margin-right: 10px;

    display: block;
}

.shopPage .goShoppingContent{
    min-width: 355px;
    text-align: center;
    font-size: 17px;
    margin: auto 50px;
}


.shopPage .goShoppingContent>.companylogo{
    width: 182px;
}

.goShoppingContent .desc{
    line-height: 30px;
    font-weight: 700;
}

.goShoppingContent> .goShopping{
    background-color: #FC840B;
    height: 75px;
    width: 273px;
    border-radius: 33px;
    font-size: 23px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px auto;
}

.goShoppingContent > .complayName{
    color:#000;
    line-height: 20px;
    font-weight: 700;
}

.goShoppingContent_layout{
    width: 815px;
    height: 800px;
    display: flex;
    justify-content: center;
    
}


@media (max-width: 768px) {
    .shopPage>.shopPageLayout{
        margin: 50px 0;
    }

    .goShoppingContent_layout{
        width: 200px;
        
    }

}