.footer {
    width: 100%;
    border-top: 1px solid #dadce0;
    background-color: #cecece;
}

.footer-content{
    max-width: 1920px;
    margin: 0 auto;
}

.footer-info {
    width: auto;
    padding: 20px 20px;
}


.company-name {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 16px;
    color:#000;
    letter-spacing: -.36px;
}

.company-info{
    letter-spacing: -.28px;
    font-size: 12px;
    font-weight: 500;
    color : #000;
    line-height: 20px;
    transform: translate(0px, 14%);
}

.footer-contact {
    display: flex;
    margin-top: 24px;
    margin-bottom: 40px;
}

.footer-contact-item {
    color : #828c94;
    transition: color .25s;
    font-size: 14 px;
    line-height: 2;
    margin-right: 24px;
    cursor: pointer;
}

.footer-contact-item-bold {
    font-weight: 700;
}
