
.homeContent{
  margin: 300px 0;
}


.homeContent>.homeLayout{
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.homeContent>.content>.logoContent{
  margin: 100px auto;
  display: inline-block;

}

.homeContent .desc {
    color:#fc840b;
    font-size: 25px;
    font-weight: 600;
    width: auto;
    margin-top:20px;
}


@media (max-width:768px)
{
  .homeContent{
    margin: 100px 0;
  }
  
  .homeContent>.homeLayout{
    transform: scale(0.6);
    white-space: nowrap;

  } 
}