

.home-nav-container {
    position: fixed;
    top: 0px;
    width: 100%;
    height: auto;
    background-color: #fff;
    padding-top: 70px;
    clear: both;
    z-index: 99;
}

.home-nav {
    width: 100%;
    padding: 0px 30px;
    max-width: 1920px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.home-nav-menu {
    display: flex;
    margin-left: 0px;
}

.home-nav-menu-item, .home-nav-menu-item--active {
    letter-spacing: .2em;
    color: #212529;
    font-size: 18px;
    margin-left: 50px;
}

.home-nav-menu-item{
    opacity: .42;
}

.home-nav-menu-item.nav-menu-item--active {
    font-weight: 700;
    opacity: 1;
}

.home-nav-menu-item>img {
    content: url('../img/nav_unselect.svg');
}

.home-nav-menu-item.nav-menu-item--active>img {
    content: url('../img/nav_select.svg');
}
.home-nav-menu-item>img, .home-nav-menu-item--active>img{

    vertical-align:middle;
    margin-left: 10px;
}



.mobile-nav-menus{
    display: flex;    
    justify-content: center;
    height: 100%;
    width: 100%;
    position:fixed;
    background-color: #fff;
    align-items: center;
    z-index: 100;
}

.mobile-nav-menus .mobile-nav-menus-list {
    text-align: center;
}

.mobile-nav-menus .mobile-nav-menus-list .mobile-nav-menus-item{
    font-size: 20px;
    letter-spacing: 4px;
    display: block;
    margin-bottom: 40px;
}

.nav-menu-item--active{
    font-weight: 700;
}

@media (max-width: 768px) {
    .home-nav-container {
        padding-top: 10px;
    }
 
    .home-nav>a>.coLogo {
        content: url('../img/m_nav_logo.png');
    }
}