


.layout>.content{
  padding-top: 110px;
  padding-bottom: 20px;
  max-width: 1920px;
  margin: 0 auto;
  z-index: 0;
}

li, ul, ol, div, body, h1, a {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}


body, html {
  line-height: 1;
  font-family: Noto Sans KR,Apple SD Gothic Neo,"\B9D1\C740 \ACE0\B515",Malgun Gothic,sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  min-height: 100%;
}

li, ol, ul {
  list-style: none;
}

@media (min-width: 768px)
{
  body, html{
    font-size: 15px;
  }
}

@media (max-width:769px){
  
  .layout>.content{
    padding-top: 50px;
  }
}
