.introducePage {
    margin: 0 20px;
}

.introducePage>.introducePageContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.introducePageContent>.introduceDescArea {
    margin: 300px auto;
    max-width: 1140px;
    display: flex;
    align-items: center;
}

.introducePageContent .companyLogoArea {
    display: flex;
    justify-content: center;
    margin: 200px auto;
}

.introducePage .desc{
    
    color:#fc840b;
    font-size: 21px;
    font-weight: 600;
    line-height: 60px;
}

.introducePageContent>.introduceDescArea .introduceDesc {
    letter-spacing: .44px;
    line-height: 35px;
    font-weight: 500;
    font-size: 22px;
}

.introducePageContent .companyLogoArea .logoContent {
    text-align: center;
    white-space: nowrap;

}

.introducePageContent .companyLogoArea .logoContent>.companyLogo {
    width: 245px;
    text-align: center;
}

@media (max-width:768px) {

    .introducePageContent>.introduceDescArea .introduceDesc {
        font-size: 14px;
    }


    .introducePageContent .companyLogoArea {
        transform: scale(0.7);
        margin: 100px auto;
        width: 100%;
    }


    .introducePageContent>.introduceDescArea {
        margin: 100px auto
    }
}